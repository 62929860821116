<!-- EventDetails.vue -->
<template>
  <div>
    <div class="headline">
      <h2>
        Willkommen zur Hochzeit von
        <span>{{ eventData.brautName }} und {{ eventData.braeutigamName }}</span
        >!
      </h2>
    </div>

    <div class="wedding-date">
      {{ formatDate(eventData.weddingDate) }}
    </div>

    <div class="button">
      <button class="invite-guests" @click="$emit('toggle-qr-code')">
        <span class="material-icons-outlined">ios_share</span>
        Gäste zur App einladen
      </button>
    </div>

    <div class="button">
      <button
        class="download-all"
        @click="$emit('download-all-images')"
        v-if="images.length > 0"
      >
        <span class="material-icons-outlined">download</span>
        Alle Bilder herunterladen
      </button>
    </div>

    <div class="gallery">
      <div class="inner">
        <div class="icon" v-if="images.length == 0">
          <span class="material-icons-outlined">photo_library</span>
        </div>

        <div class="text" v-if="images.length == 0">
          Noch keine Bilder vorhanden
        </div>

        <div class="images" v-else>
          <div
            v-for="(image, index) in images"
            :key="index"
            :class="['image-item', `image-item-${index}`]"
            @click="$emit('open-lightbox', index)"
          >
            <transition name="fade">
              <img
                :src="`/images/${image.fileName}`"
                :alt="`Bild ${index + 1}`"
                @load="onImageLoad(index)"
                v-show="loadedImages[index]"
              />
            </transition>
            <transition name="fade">
              <div v-show="!loadedImages[index]" class="image-placeholder">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                  <rect class="square" x="0" y="0" width="90" height="90">
                    <animate
                      attributeName="opacity"
                      values="0.3;1;0.3"
                      dur="1.5s"
                      repeatCount="indefinite"
                      begin="0s"
                    />
                  </rect>
                  <!-- ... (weitere Rechtecke für die Animation) ... -->
                </svg>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventDetails",
  props: {
    eventData: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loadedImages: [],
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    onImageLoad(index) {
      this.loadedImages[index] = true;
      this.loadedImages = [...this.loadedImages];
    },
  },
  watch: {
    images: {
      immediate: true,
      handler(newImages) {
        this.loadedImages = new Array(newImages.length).fill(false);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  h2 {
    font-size: 1.6em;

    span {
      color: $primary-color;
    }
  }
}

.wedding-date {
  color: $md-grey;
}

button {
  &.invite-guests {
    margin: 16px 0 0;
    background-color: $accent-color;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons-outlined {
      margin-right: 4px;
    }
  }

  &.download-all {
    margin: 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons-outlined {
      margin-right: 4px;
    }
  }
}

.gallery {
  display: flex;
  min-height: 240px;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 85px;
    position: fixed;
    bottom: 65px;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .inner {
    padding: 20px 0 160px;
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    width: 100%;

    .images {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 -20px;
      width: calc(100% + 40px);
      border-top: 3px solid $primary-color;

      .image-item {
        width: 33.333333%;
        padding: 5px;
        position: relative;
        padding-bottom: 33.333333%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .image-placeholder {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 24px;
            height: auto;
          }
        }
      }
    }

    .icon {
      width: 100%;

      span {
        font-size: 80px;
        color: $primary-color;
      }
    }

    .text {
      width: 100%;
      font-weight: 600;
      color: $md-grey;
    }
  }
}

.square {
  fill: #e9c3a0;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}
</style>
