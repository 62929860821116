<template>
  <nav class="bottom-bar">
    <router-link
      v-for="item in navItems"
      :key="item.route"
      :to="item.route"
      class="nav-item"
      :class="{ active: isActive(item.route) }"
    >
      <span class="material-icons-outlined">{{ item.icon }}</span>
      <span class="material-icons">{{ item.icon }}</span>
      <span class="label">{{ item.label }}</span>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "BottomBar",
  data() {
    return {
      navItems: [
        { route: "/", icon: "home", label: "Startseite" },
        { route: "/challenges", icon: "emoji_events", label: "Challenges" },
        { route: "/ranking", icon: "leaderboard", label: "Rangliste" },
      ],
    };
  },
  methods: {
    isActive(route) {
      if (route === "/") {
        // Aktiv für Startseite und Event-Ansicht
        return this.$route.path === "/" || this.$route.name === "event";
      }
      // Für alle anderen Routen normal prüfen
      return this.$route.path === route;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #1976d2;
$inactive-color: #757575;
$background-color: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-around;
  background-color: $background-color;
  box-shadow: 0 -2px 5px $shadow-color;
  height: 65px;
  padding: 0;
  z-index: 11;
}

.nav-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  text-decoration: none;
  color: $inactive-color;
  transition: color 0.3s ease;

  .material-icons,
  .material-icons-outlined {
    font-size: 24px;
    transition: transform 0.3s ease;
  }

  .material-icons {
    display: none;
  }

  .label {
    font-size: 12px;
    margin-top: 4px;
  }

  &.active {
    color: $primary-color;

    .material-icons-outlined {
      display: none;
    }

    .material-icons {
      display: inline;
    }
  }

  &:hover {
    color: lighten($primary-color, 10%);

    .material-icons,
    .material-icons-outlined {
      transform: scale(1.1);
    }
  }
}
</style>
