<!-- ImageUploader.vue -->
<template>
  <div>
    <div class="button">
      <button class="add-image" @click="triggerFileInput">
        Bild hinzufügen
      </button>
      <input
        type="file"
        ref="fileInput"
        @change="handleFileUpload"
        accept="image/*"
        multiple
        style="display: none"
      />
    </div>
    <div v-if="showUpload" class="upload-progress" v-html="uploadText"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImageUploader",
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showUpload: false,
      uploadText: "",
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const files = event.target.files;
      if (files.length === 0) return;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("images[]", files[i]);
      }
      formData.append("eventId", this.eventId);

      try {
        this.uploadText =
          "Bilder werden hochgeladen.<br />Warte bitte einen Moment.";
        this.showUpload = true;

        const response = await axios.post(
          "https://wedding.webixel.de/php/upload_images.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.uploadText = "Bilder erfolgreich hochgeladen!";
        console.log("Images uploaded:", response.data);
        this.$emit("images-uploaded");

        setTimeout(() => {
          this.showUpload = false;
        }, 2000);
      } catch (error) {
        console.error("Error uploading images:", error);
        this.uploadText =
          "Fehler beim Hochladen der Bilder. Bitte versuchen Sie es erneut.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: fixed;
  bottom: 85px;
  left: 20px;
  width: calc(100% - 40px);
  z-index: 2;
}

.upload-progress {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $lg-grey;
  z-index: 10;
  padding: 1em 2em;
  text-align: center;
  font-weight: bold;
  width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}
</style>
