<!-- PasswordPrompt.vue -->
<template>
  <div class="passwort-prompt">
    <div class="inner">
      <h2>Bitte geben Sie das Passwort ein</h2>
      <form @submit.prevent="checkPassword">
        <label for="name">Dein Name</label>
        <input
          name="name"
          type="text"
          v-model="guestName"
          placeholder="Dein Name"
          @input="validateName"
          required
        />
        <label for="password">Passwort der Veranstaltung</label>
        <input
          name="password"
          type="password"
          v-model="enteredPassword"
          placeholder="Passwort (6 Zeichen)"
          minlength="6"
          required
        />
        <button type="submit">Los geht's!</button>
      </form>
      <p v-if="nameError" class="error-message">
        Der Name darf nur Buchstaben und Leerzeichen enthalten.
      </p>
      <p v-if="passwordError" class="error-message">
        Falsches Passwort. Bitte versuchen Sie es erneut.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordPrompt",
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      guestName: "",
      enteredPassword: "",
      passwordError: false,
      nameError: false,
      isValidName: false,
    };
  },
  methods: {
    async checkPassword() {
      if (!this.isValidName) {
        this.nameError = true;
        return;
      }

      try {
        const response = await fetch(
          "/php/user_auth.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: this.guestName,
              password: this.enteredPassword,
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          localStorage.setItem(`guestName_${this.eventId}`, this.guestName);
          localStorage.setItem(`userId_${this.eventId}`, data.userId);
          this.passwordError = false;
          this.$emit("authentication-success");
        } else {
          this.passwordError = true;
        }
      } catch (error) {
        console.error("Fehler bei der Authentifizierung:", error);
        this.passwordError = true;
      }
    },
    validateName() {
      const nameRegex = /^[a-zA-ZäöüÄÖÜß\s]+$/;
      this.isValidName = nameRegex.test(this.guestName);
      this.nameError = !this.isValidName && this.guestName.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.passwort-prompt {
  height: 100%;
  display: flex;
  align-items: center;

  button {
    margin: 16px 0 0;
  }

  form {
    margin: 32px 0 0;

    input + label {
      margin-top: 16px;
    }

    label {
      font-weight: 600;
      font-size: 0.85em;
    }

    input {
      text-align: center;
    }
  }
}

.error-message {
  color: red;
  margin-top: 10px;
}
</style>
