<template>
  <div class="challenge-view">
    <h2>Hochzeits-Challenges</h2>

    <div class="challenge-info">
      <span class="name">{{ guestName }}</span>
      <span class="points">
        <span class="current">{{ points }}</span> von
        <span class="total">{{ challenges.length }}</span>
      </span>
    </div>

    <ul class="challenge-list">
      <li
        v-for="challenge in challenges"
        :key="challenge.id"
        class="challenge-item"
        :class="{ completed: challenge.completed }"
      >
        <label>
          <input
            type="checkbox"
            :checked="challenge.completed"
            @change="toggleChallenge(challenge)"
          />
          <div class="value">
            {{ challenge.challenge }}
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChallengeView",
  data() {
    return {
      challenges: [],
      points: 0,
      eventId: null,
      guestName: "",
    };
  },
  created() {
    this.eventId = this.$route.params.id || localStorage.getItem("eventId");
    this.guestName =
      localStorage.getItem(`guestName_${this.eventId}`) || "Gast";
    this.userId = localStorage.getItem(`userId_${this.eventId}`) || "Gast";
    this.loadChallenges();
    this.loadPoints();
  },
  methods: {
    async loadChallenges() {
      try {
        const response = await axios.get(
          `/php/get_challenges.php?eventId=${this.eventId}`,
          {
            baseURL: process.env.VUE_APP_API_BASE_URL || "",
          }
        );

        /*
        this.challenges = response.data.map((challenge) => ({
          ...challenge,
          completed: false,
        }));
        this.loadCompletedChallenges();
        */

        if (response.data && Array.isArray(response.data)) {
          this.challenges = response.data.map((challenge) => ({
            ...challenge,
            completed: false,
          }));
          this.loadCompletedChallenges();
        } else {
          console.error("Ungültige Antwortdaten:", response.data);
        }
      } catch (error) {
        console.error("Fehler beim Laden der Challenges:", error);
        // Detaillierte Fehlerprotokollierung
        if (error.response) {
          console.error("Antwortdaten:", error.response.data);
          console.error("Antwortstatus:", error.response.status);
        }
      }
    },
    loadCompletedChallenges() {
      const completedChallenges =
        JSON.parse(
          localStorage.getItem(
            `completedChallenges_${this.eventId}_${this.guestName}`
          )
        ) || [];
      this.challenges.forEach((challenge) => {
        challenge.completed = completedChallenges.includes(challenge.id);
      });
    },
    async toggleChallenge(challenge) {
      challenge.completed = !challenge.completed;
      if (challenge.completed) {
        this.points++;
      } else {
        this.points--;
      }
      this.saveCompletedChallenges();
      this.savePoints();

      try {
        const response = await axios.post(
          "/php/update_challenge.php",
          {
            eventId: this.eventId,
            guestName: this.userId,
            challengeId: challenge.id,
            completed: challenge.completed,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: false,
          }
        );
        console.log(response);
      } catch (error) {
        console.error("Error updating challenge status:", error);
      }
    },
    saveCompletedChallenges() {
      const completedChallenges = this.challenges
        .filter((challenge) => challenge.completed)
        .map((challenge) => challenge.id);
      localStorage.setItem(
        `completedChallenges_${this.eventId}_${this.guestName}`,
        JSON.stringify(completedChallenges)
      );
    },
    loadPoints() {
      this.points =
        parseInt(
          localStorage.getItem(`points_${this.eventId}_${this.guestName}`)
        ) || 0;
    },
    savePoints() {
      localStorage.setItem(
        `points_${this.eventId}_${this.guestName}`,
        this.points.toString()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin transition($property: all, $duration: 0.3s, $timing: ease) {
  transition: $property $duration $timing;
}

.challenge-view {
  margin: 0 auto;
  padding: 60px 20px 85px;
  height: 100%;
  position: relative;

  .challenge-info {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $lg-grey;
    padding-top: 16px;
    margin: 0 0 24px;

    .points {
      text-align: right;
      white-space: nowrap;

      .current {
        font-weight: 600;
      }

      .total {
        font-weight: 600;
      }
    }
  }

  .challenge-list {
    list-style-type: none;
    padding: 0 0 80px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;

    .challenge-item {
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      cursor: pointer;
      margin: 8px 0;

      &.completed {
        order: 500;

        label {
          background-color: $success-color;
        }

        .value {
          text-decoration: line-through;
        }
      }

      label {
        padding: 15px 15px 15px 55px;
        background-color: $lg-grey;
        border-radius: 16px;
        width: 100%;
        transition: 0.25s ease-in-out;

        input[type="checkbox"] {
          margin-right: 10px;
          cursor: pointer;
          width: 50px;
          position: absolute;
          opacity: 0;
          visibility: hidden;

          &:checked {
            & + .value {
              &::after {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        .value {
          transition: 0.25s ease-in-out;

          &::before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            background-color: $white;
            margin-right: 12px;
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 8px;
          }

          &::after {
            content: "✓";
            text-align: center;
            width: 24px;
            height: 24px;
            background-color: $white;
            margin-right: 12px;
            position: absolute;
            left: 15px;
            top: 15px;
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-in-out;
            line-height: 24px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 8px;
          }
        }
      }
    }
  }
}
</style>
