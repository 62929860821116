import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChallengeView from '../views/ChallengeView.vue'
import RankingView from '../views/RankingView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/challenges',
    name: 'challenges',
    component: ChallengeView
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: RankingView
  },
  {
    path: '/:id(\\w{8})',
    name: 'event',
    component: HomeView,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const eventId = localStorage.getItem('eventId')
  if (to.name === 'event' && to.params.id) {
    if (to.params.id.length === 8) {
      localStorage.setItem('eventId', to.params.id)
      next()
    } else {
      next({ name: 'home' })
    }
  } else if (to.name === 'home' && eventId) {
    next({ name: 'event', params: { id: eventId } })
  } else {
    next()
  }
})

export default router
