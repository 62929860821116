<!-- HomeView.vue -->
<template>
  <div class="home" :class="{ 'overlay-active': showQRCode }">
    <NewEventForm v-if="!eventId" @event-created="handleEventCreated" />
    <PasswordPrompt
      v-else-if="!isAuthenticated"
      :eventId="eventId"
      @authentication-success="handleAuthenticationSuccess"
    />
    <EventDetails
      v-else-if="eventLoaded"
      :eventData="eventData"
      :images="images"
      @toggle-qr-code="toggleQRCode"
      @download-all-images="downloadAllImages"
      @open-lightbox="openLightbox"
    />
    <ImageUploader v-if="eventLoaded" :eventId="eventId" @images-uploaded="handleImagesUploaded" />
    <QRCodeOverlay
      :showQRCode="showQRCode"
      :eventUrl="eventUrl"
      @close="closeQRCode"
    />
    <LightBox
      v-if="lightboxVisible"
      :images="images"
      :initialIndex="lightboxIndex"
      @close="closeLightbox"
      @share="shareImage"
    />

    
  </div>
</template>

<script>
import NewEventForm from "@/components/NewEventForm.vue";
import PasswordPrompt from "@/components/PasswordPrompt.vue";
import EventDetails from "@/components/EventDetails.vue";
import ImageUploader from "@/components/ImageUploader.vue";
import QRCodeOverlay from "@/components/QRCodeOverlay.vue";
import LightBox from "@/components/LightBox.vue";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default {
  name: "HomeView",
  components: {
    NewEventForm,
    PasswordPrompt,
    EventDetails,
    ImageUploader,
    QRCodeOverlay,
    LightBox,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      eventId: null,
      eventUrl: "",
      showQRCode: false,
      eventLoaded: false,
      images: [],
      lightboxVisible: false,
      lightboxIndex: 0,
      isAuthenticated: false,
      eventData: {},
      loadedImages: [],
    };
  },
  created() {
    if (this.id) {
      this.eventId = this.id;
    } else {
      const storedEventId = localStorage.getItem("eventId");
      if (storedEventId) {
        this.eventId = storedEventId;
      }
    }

    this.checkAuthentication();
    if (this.isAuthenticated) {
      this.loadEventDetails(this.eventId);
    }
  },
  methods: {
    handleEventCreated(eventId) {
      this.eventId = eventId;
      localStorage.setItem("eventId", eventId);
      this.setEventUrl(eventId);
      this.$router.push(`/${eventId}/`);
      this.loadEventDetails(eventId);
    },
    handleAuthenticationSuccess() {
      this.isAuthenticated = true;
      localStorage.setItem(`auth_${this.eventId}`, "true");
      this.loadEventDetails(this.eventId);
    },
    async loadEventDetails(eventId) {
      try {
        const response = await axios.get(
          "https://wedding.webixel.de/php/get_event.php?id=" + eventId
        );
        this.eventData = response.data;
        this.setEventUrl(eventId);
        this.eventLoaded = true;
        this.loadImages();
      } catch (error) {
        console.error("Error loading event details:", error);
      }
    },
    setEventUrl(eventId) {
      this.eventUrl = `${window.location.origin}/${eventId}/`;
    },
    toggleQRCode() {
      this.showQRCode = !this.showQRCode;
    },
    closeQRCode() {
      this.showQRCode = false;
    },
    handleImagesUploaded() {
      this.loadImages();
    },
    async loadImages() {
      try {
        const response = await axios.get(
          `https://wedding.webixel.de/php/get_images.php?eventId=${this.eventId}`
        );
        this.images = response.data;
        this.loadedImages = new Array(this.images.length).fill(false);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    },
    openLightbox(index) {
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    closeLightbox() {
      this.lightboxVisible = false;
    },
    async downloadAllImages() {
      const zip = new JSZip();
      const promises = this.images.map(async (image, index) => {
        const imageUrl = `https://wedding.webixel.de/images/${image.fileName}`;
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        zip.file(`bild_${index + 1}.jpg`, blob);
      });

      await Promise.all(promises);

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "hochzeitsbilder.zip");
    },
    checkAuthentication() {
      const storedAuth = localStorage.getItem(`auth_${this.eventId}`);
      this.isAuthenticated = storedAuth === "true";
    },
    async shareImage(index) {
      const imageUrl = `https://wedding.webixel.de/images/${this.images[index].fileName}`;
      const imageTitle = `Schau dir das Bild an!`;

      if (navigator.share) {
        try {
          await navigator.share({
            title: "Hochzeitsfoto",
            text: imageTitle,
            url: imageUrl,
          });
          console.log("Erfolgreich geteilt");
        } catch (error) {
          console.error("Fehler beim Teilen:", error);
        }
      } else {
        alert("Teilen wird auf diesem Gerät nicht unterstützt");
      }
    },
  },
};
</script>

<style lang="scss">
.home {
  margin: 0 auto;
  padding: 60px 20px 85px;
  height: 100%;
  position: relative;

  .overlay {
    content: "";
    display: block;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 0;
    visibility: hidden;
    transition: 0.35s ease-in-out;
  }

  &.overlay-active {
    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
