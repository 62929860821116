<template>
  <div class="ranking-view">
    <h2>Rangliste</h2>
    <table v-if="ranking.length">
      <thead>
        <tr>
          <th>Platz</th>
          <th>Name</th>
          <th>Challenges</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(user, index) in ranking"
          :key="user.user_id"
          :class="{ 'current-user': user.id == currentUserId }"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.completed_challenges }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>Keine Ranglistendaten verfügbar.</p>
  </div>
</template>

<script>
export default {
  name: "RankingView",
  data() {
    return {
      ranking: [],
      eventId: null,
      currentUserId: null,
    };
  },
  mounted() {
    this.loadEventIdAndUserId();
    this.fetchRanking();
  },
  methods: {
    loadEventIdAndUserId() {
      // Wir nehmen an, dass der Event-Schlüssel im LocalStorage konsistent ist
      const keys = Object.keys(localStorage);
      const eventKey = keys.find((key) => key.startsWith("auth_"));
      if (eventKey) {
        this.eventId = eventKey.split("_")[1];
        this.currentUserId = localStorage.getItem(`userId_${this.eventId}`);
      } else {
        console.error("Keine Event-ID im LocalStorage gefunden");
      }
    },
    async fetchRanking() {
      if (!this.eventId) {
        console.error("Keine Event-ID verfügbar");
        return;
      }

      try {
        const response = await fetch(
          `https://wedding.webixel.de/php/get_ranking.php?eventId=${this.eventId}`
        );
        if (!response.ok) {
          throw new Error("Netzwerkantwort war nicht ok");
        }
        else {
          console.log(response);
        }
        const data = await response.json();
        this.ranking = data;
      } catch (error) {
        console.error("Fehler beim Abrufen der Rangliste:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ranking-view {
  padding: 60px 20px 85px;
  height: 100%;
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;

  tr {
    &:nth-child(even) {
      td:nth-child(odd) {
        background-color: #f0f0f0;
      }
      td:nth-child(even) {
        background-color: $lg-grey;
      }
    }
    &:nth-child(odd) {
      td:nth-child(odd) {
        background-color: $lg-grey;
      }
      td:nth-child(even) {
        background-color: #f0f0f0;
      }
    }

    &.current-user {
      font-weight: bold;

      td {
        background-color: $success-color !important;
      }
    }

    th {
      border: 1px solid $primary-color;
      padding: 8px;
      text-align: left;
      background-color: $primary-color;
      font-weight: bold;
    }

    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
  }
}
</style>
