<!-- QRCodeOverlay.vue -->
<template>
  <div>
    <div class="overlay" @click="$emit('close')"></div>
    <div :class="['qr-code', { in: showQRCode }]">
      <div class="outer"></div>
      <h3>QR-Code für Einladungen:</h3>
      <qrcode-vue
        :background="'#ededed'"
        :foreground="'#111013'"
        :value="eventUrl"
        :size="200"
        level="H"
      />
      <div class="password"><span>Passwort:</span> <b>375876</b></div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "QRCodeOverlay",
  components: {
    QrcodeVue,
  },
  props: {
    showQRCode: {
      type: Boolean,
      required: true,
    },
    eventUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  content: "";
  display: block;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  visibility: hidden;
  transition: 0.35s ease-in-out;
}

.qr-code {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 32px 40px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border: 1px solid #eee;
  background-color: $lg-grey;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  transition: 0.35s ease-in-out;
  bottom: 65px;
  transform: translateY(100%);

  &.in {
    transform: translateY(0%);
  }

  h3 {
    font-size: 1.2em;
  }

  .password {
    font-size: 1.2em;
    margin: 16px 0;
    padding: 12px;
    background-color: $primary-color;
    border-radius: 50px;

    b {
      color: $accent-color;
    }
  }
}
</style>
