<!-- Lightbox.vue -->
<template>
  <div class="lightbox" @click="$emit('close')">
    <div class="lightbox-content" @click.stop>
      <div class="lightbox-close" @click="$emit('close')">×</div>
      <div class="img-counter">
        <span>{{ `Bild ${currentIndex + 1} von ${images.length}` }}</span>
      </div>

      <div class="image">
        <div class="img">
          <img
            :src="`/images/${images[currentIndex].fileName}`"
          />
        </div>
      </div>

      <div class="lightbox-nav">
        <div class="lightbox-prev" @click="prevImage">
          <span class="material-icons">arrow_back_ios</span>
        </div>
        <div class="lightbox-share" @click="$emit('share', currentIndex)">
          <span class="material-icons">ios_share</span>
          <span>Teilen</span>
        </div>
        <div class="lightbox-next" @click="nextImage">
          <span class="material-icons">arrow_forward_ios</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LightBox",
  props: {
    images: {
      type: Array,
      required: true,
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentIndex: this.initialIndex,
    };
  },
  methods: {
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.images.length - 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;

  .lightbox-content {
    position: relative;
    background: #fff;
    padding: 0 0 60px;
    border-radius: 12px;
    text-align: center;
    line-height: 0;
    width: 100%;

    .img-counter {
      line-height: 1.4;
      position: absolute;
      height: auto;
      bottom: 0;
      left: 0;
      color: #fff;
      z-index: 999;
      transform: translateY(100%);
      padding-top: 10px;
      text-align: center;
      width: 100%;
      font-size: 0.85em;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      font-weight: 500;
    }

    .image {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      border-bottom: 3px solid $primary-color;

      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }

  .lightbox-nav {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;

    .lightbox-prev,
    .lightbox-next {
      width: 60px;
      font-size: 32px;
      cursor: pointer;
    }

    .lightbox-share {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span.material-icons {
        margin-right: 4px;
      }
    }
  }

  .lightbox-close {
    top: 10px;
    right: 10px;
    transform: none;
    font-size: 40px;
    position: fixed;
    color: $white;
    cursor: pointer;
  }
}
</style>
