<!-- NewEventForm.vue -->
<template>
  <div class="new-event">
    <h1>Neues Event erstellen</h1>
    <form @submit.prevent="createEvent">
      <div>
        <label for="brautName">Name der Braut:</label>
        <input id="brautName" v-model="brautName" required />
      </div>
      <div>
        <label for="braeutigamName">Name des Bräutigams:</label>
        <input id="braeutigamName" v-model="braeutigamName" required />
      </div>
      <div>
        <label for="hochzeitstag">Hochzeitstag:</label>
        <input id="hochzeitstag" type="date" v-model="hochzeitstag" required />
      </div>
      <div>
        <label for="creatorName">Dein Name:</label>
        <input id="creatorName" v-model="creatorName" required />
      </div>
      <button type="submit">Anlegen</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewEventForm",
  data() {
    return {
      brautName: "",
      braeutigamName: "",
      hochzeitstag: "",
      creatorName: "",
    };
  },
  methods: {
    generateEventID() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    async createEvent() {
      const eventID = this.generateEventID();
      try {
        const response = await axios.post(
          "/php/create_event.php",
          {
            eventID: eventID,
            brautName: this.brautName,
            braeutigamName: this.braeutigamName,
            weddingDate: this.hochzeitstag,
            creatorName: this.creatorName,
          }
        );
        console.log("Event created:", response.data);
        this.$emit("event-created", eventID);
      } catch (error) {
        console.error("Error creating event:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  div {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
  }
}
</style>
